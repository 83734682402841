

import UMUM from         '../../library/umum'
import FETCHING from         '../../library/fetching'

export default {
  data() {
    return {
      list_data : [],

      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,

      filterku : {
        unit_kerja_id : '',
        date : '',
        bulan : '',
        tahun : '',
        waktuFirst: '',
        waktuLast : '',
      },

      uraian : {
        unit_kerja : '',
      },

      UMUM :UMUM,
      FETCHING : FETCHING,




      mdl_printing: false,
      simpan1: false,
      model :'',
      inputSelect : [
        {id : '1', nama : 'kiken', alamat : 'beringin'},
        {id : '2',nama : 'Alung', alamat : 'Anawai'},
      ]
    }
  },
  methods: {
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_presensi_lapCustom + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              date : this.filterku.date,
              bulan : this.filterku.bulan,
              tahun : this.filterku.tahun,
              unit_kerja_id : this.filterku.unit_kerja_id,
              data_ke: this.page_first,
              cari_value: this.cari_value,

              waktuFirst: this.filterku.waktuFirst,
              waktuLast: this.filterku.waktuLast,

          })
      })
          .then(res => res.json())
          .then(res_data => {
            this.$store.commit("hideLoading");
            this.list_data = res_data
              // console.log(res_data);
      });
    },


    searchByDate(){
      var waktuFirst = this.filterku.waktuFirst
      var waktuLast = this.filterku.waktuLast


      var date = waktuFirst.split('/')
      this.filterku.tahun = date[0];
      this.filterku.bulan = parseInt(date[1]);
      this.filterku.date = date[2];

      this.getView()
    },





    filterUnitKerja : function (val, update, abort) {
        update(() => {
          if (val === '') {}
            else {
            FETCHING.postUnitKerjaAuto(val)
            }
        })
    },



    simulateProgress (number) {
      // we set loading state
      this[`simpan${number}`] = true
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        this[`simpan${number}`] = false
      }, 3000)
    },


    async showChannel(val){
      // console.log(val)
      var data = await FETCHING.postUnitKerjaId('', val)
      this.uraian.unit_kerja = data[0].unit_kerja
    },




    print () {
      // Pass the element id here
      this.$htmlToPaper('printMe');
    },


    pembulatan : function(data){
      if (data == null || data == undefined || data == '') {
        return 0
      } else {
        // console.log('ASSSSSSSSSSSSSS');
        var angka = data;
        var hasil = angka.toFixed(1);
        return hasil
        
      }
    }







  },


  mounted() {

    const d = new Date();

    // this.filterku.waktuFirst = d.getFullYear()+'/'+(d.getMonth()+1)+'/'+d.getDate()
    // this.filterku.waktuLast = d.getFullYear()+'/'+(d.getMonth()+1)+'/'+d.getDate()


    var tahun = d.getFullYear();
    var bulan = d.getMonth()+1;
    var date = d.getDate()

    var bulanModiv = ''

    this.filterku.waktuFirst = d.getFullYear()+'/'+"01"+'/'+"1"
    this.filterku.waktuLast = tahun+'/'+ UMUM.addZeroFirst(bulan) +'/'+date

    
    this.filterku.tahun = tahun;
    this.filterku.bulan = bulan;
    this.filterku.date = date


    this.filterku.unit_kerja_id = this.$store.state.unit_kerja
    this.FETCHING.postUnitKerjaAuto('', this.filterku.unit_kerja_id)

    this.showChannel(this.$store.state.unit_kerja)

    this.getView()






  },
}
